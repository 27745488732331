import { CardGrid, ContentCard, Group, Panel, PanelHeader, PanelHeaderBack, Search, Separator, Spacing } from "@vkontakte/vkui"
import { formatDatetime } from "../../../utils/fomatDateTime"
import { TMainPanel } from "../MainPanel"
import { Icon24Filter } from "@vkontakte/icons"
import { useEffect, useState } from "react"
import { IEvent } from "../../../store/slices/eventsSlice"
import { useFilters } from "../../../hooks/useFilters"
import axios from "axios"
import { useToken } from "../../../hooks/useToken"
import {getUrl} from "../../../api/api";

interface IEventsListProps {
  id: TMainPanel
  goToPanel: (value: TMainPanel, id?: string) => void
  goBack: (value: TMainPanel) => void
}

const EventsList = ({ id, goToPanel, goBack }:IEventsListProps) => {
  const [search, setSearch] = useState('')
  const filters = useFilters()
  const [eventsData, setEventsData] = useState<IEvent[]>([])
  const [page, setPage] = useState(1)
  const [fetching, setFetching] = useState(true)
  const token = useToken()

  useEffect(() => {
    if (!filters.selectedLevel && !filters.selectedType) {
      if (fetching) {
        console.log('fetching')
        axios.get(getUrl(`/api/v1/events/event/index?page=${page}&sort=-date_start`), {
          headers: { Authorization: `Bearer ${token}` }
        }).then(res => {
          if (page > res.data._meta.pageCount) return
          setEventsData([...eventsData, ...res.data.data])
          setPage(prev => prev + 1)
        }).finally(() => {
          setFetching(false)
        })
      }
    } else {
      axios.get(getUrl(`/api/v1/events/event/index?type_id=${filters.selectedType ? filters.selectedType : ''}&level_id=${filters.selectedLevel ? filters.selectedLevel : ''}`), {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then(res => setEventsData(res.data.data))
    }
  }, [fetching])


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
    axios.get(getUrl(`/api/v1/events/event/index?indextitle=${event.target.value}`), {
      headers: { Authorization: `Bearer ${token}` }
    }).then(res => {
      setEventsData(res.data.data)
    })
  }


  return (
    <Panel id={id}>
      <PanelHeader before={<PanelHeaderBack onClick={() => goBack('main')} />}>Молодёжь 39</PanelHeader>
      <Search
        value={search}
        onChange={handleChange}
        icon={<Icon24Filter fill={(filters.selectedLevel || filters.selectedType) && '#2688EB' }/>}
        onIconClick={() => goToPanel('filter')}
        placeholder={'Поиск по мероприятиям'}
      />
      <Group>
        <CardGrid size="l">
          {eventsData.length > 0 && eventsData.map(event => (
            <ContentCard
              key={event.id}
              src={event.image_url_list[0] ? event.image_url_list[0].url_path : '/img/not-image.png'}
              header={event.title}
              // caption={formatDatetime(event.date_start * 1000, 'list') + '<span>asdasd</span>' + event.type_title}
                caption={[formatDatetime(event.date_start * 1000, 'list'), <span className={'ContentCard--span'}>{event.type_title}</span>]}
              onClick={() => goToPanel('eventDetails', event.id)}
              height={112}
            />
          ))}
        </CardGrid>
      </Group>
    </Panel>
  )
}

export default EventsList
