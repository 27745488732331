import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { useToken } from '../../hooks/useToken'
import {getUrl} from "../../api/api";

export const getFiltersData = createAsyncThunk(
  'getFiltersData',
  async (token: string) => {
    const type = await axios.get(getUrl('/api/v1/events/type/index'), {
      headers: { Authorization: `Bearer ${token}` }
    }).then(res => res.data.data)

    const level = await axios.get(getUrl('/api/v1/events/level/index'), {
      headers: { Authorization: `Bearer ${token}` }
    }).then(res => res.data.data)

    return {type, level}
  }
)

export interface IFilter {
  id: number
  title: string
}

interface IInitialState {
  type: IFilter[]
  level: IFilter[]
  selectedType: string | undefined
  selectedLevel: string | undefined
}

const initialState: IInitialState = {
  type: [],
  level: [],
  selectedType: undefined,
  selectedLevel: undefined,
}

export const FiltersSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setType: (state, action: PayloadAction<string | undefined>) => {
      state.selectedType = action.payload
    },
    setLevel: (state, action: PayloadAction<string | undefined>) => {
      state.selectedLevel = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFiltersData.fulfilled, (state, action) => {
        state.type = action.payload.type
        state.level = action.payload.level
      })
  }
})

export const { setType, setLevel } = FiltersSlice.actions

export default FiltersSlice.reducer
