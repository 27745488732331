interface IOnboarding {
  id: string
  title: string
  descr: string
  img: string
}

export const onboardings: IOnboarding[] = [
  {
    id: '1', 
    title: 'Добро пожаловать!', 
    descr: 'В мобильном приложении ты можешь просматривать новости, записываться на мероприятия, бронировать пространства и многое другое!', 
    img: "/img/onboarding-1.png"
  },
  {
    id: '2', 
    title: 'Узнавай новости', 
    descr: 'В ленте новостей ты всегда будешь в курсе последних событий', 
    img: "/img/onboarding-2.png"
  },
  {
    id: '1', 
    title: 'Будь в курсе мероприятий', 
    descr: 'Листай карточки мероприятий, чтобы получить все подробности и записаться', 
    img: "/img/onboarding-3.png"
  },
  {
    id: '1', 
    title: 'Бронируй пространства', 
    descr: 'Где бы ты ни находился, у нас всегда найдется место для твоего досуга ', 
    img: "/img/onboarding-4.png"
  },
  {
    id: '1', 
    title: 'Оставляй обратную связь', 
    descr: 'Мы ценим твое мнение, поделись своими впечатлениями после посещения мероприятия или просто оставь нам свои комментарии и предложения', 
    img: "/img/onboarding-5.png"
  }
]