import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { useToken } from '../../hooks/useToken'
import {getUrl} from "../../api/api";

export const getEventsData = createAsyncThunk(
  'getEventsData',
  async (token: string) => {
    const data = await axios.get(getUrl('/api/v1/events/event/index'), {
      headers: { Authorization: `Bearer ${token}` }
    }).then(res => res.data.data)
    return data
  }
)

export const getMyEventsData = createAsyncThunk(
  'getMyEventsData',
  async (token: string) => {
    const data = await axios.get(getUrl('/api/v1/user/user/list-events-will-go'), {
      headers: { Authorization: `Bearer ${token}` }
    }).then(res => res.data.data)
    return data
  }
)

export interface IEvent {
  [index: string]: any
}

interface IInitialState {
  list: IEvent[]
  myList: IEvent[]
}

const initialState: IInitialState = {
  list: [],
  myList: []
}

export const newsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setEvents: (state, action: PayloadAction<IEvent[]>) => {
      state.list = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEventsData.fulfilled, (state, action) => {
        state.list = action.payload
      })
      .addCase(getMyEventsData.fulfilled, (state, action) => {
        state.myList = action.payload
      })
  }
})

export const { setEvents } = newsSlice.actions

export default newsSlice.reducer
