import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {getUrl} from "../../api/api";

export const getGospublicsData = createAsyncThunk(
  'getGospublicsData',
  async (token: string) => {
    const data = await axios.get(getUrl('/api/v1/gospublic/gospublic/index'), {
      headers: { Authorization: `Bearer ${token}` }
    }).then(res => res.data.data)
    return data
  }
)

export interface IGospublic {
  [index: string]: any
}

interface IInitialState {
  list: IGospublic[]
}

const initialState: IInitialState = {
  list: []
}

export const gospublicsSlice = createSlice({
  name: 'gospublics',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGospublicsData.fulfilled, (state, action) => {
        state.list = action.payload
      })
  }
})

// export const { setNews } = newsSlice.actions

export default gospublicsSlice.reducer
