import bridge from '@vkontakte/vk-bridge';
import '@vkontakte/vkui/dist/vkui.css';
import { useEffect, useState } from 'react';

interface IPanelWrapper {
  children: React.ReactNode
}

type TTheme = 'dark' | 'light'

const PanelWrapper = ({ children }:IPanelWrapper) => {
  const [theme, setThem] = useState<TTheme>('light')
  useEffect(() => {
    bridge.send('VKWebAppGetConfig')
    .then((data) => { 
      setThem(data.appearance)
    })
  }, [])

  return (
    <div style={theme === 'light' ? pannelWrapperStyles : pannelWrapperDarkStyles}>
      {children}
    </div>
  )
}


const pannelWrapperStyles = {
  backgroundColor: '#F5F5F5',
  marginBottom: '-60px',
  minHeight: "90vh"
}

const pannelWrapperDarkStyles = {
  backgroundColor: '#232324',
  marginBottom: '-60px',
  minHeight: "90vh"
}

export default PanelWrapper