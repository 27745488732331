import { Panel, View } from "@vkontakte/vkui"
import { useState } from "react";

import EventsDetails from "./Events/EventDetails";
import Events from "./Events/Events";


interface ICalendarPanelProps {
  id: string
}

export type TCalendarPanel = 'events' | 'eventDetails'

const CalendarPanel = ({ id }:ICalendarPanelProps) => {
  const [activePanel, setActivePanel] = useState('events')
  const [eventId, setEventId] = useState('')

  const goToDetails = (id: string) => {
    setEventId(id)
    setActivePanel('eventDetails')
  }

  return (
    <Panel id={id} >
      <View activePanel={activePanel}>
        <Events id="events" goToDetails={goToDetails} />
        <EventsDetails id={'eventDetails'} goBack={() => setActivePanel('events')} eventId={eventId} />
      </View>
    </Panel>
  )
}

export default CalendarPanel