import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {getUrl} from "../../api/api";

export const getNewsData = createAsyncThunk(
  'getNewsData',
  async (token: string) => {
    const data = await axios.get(getUrl('/api/v1/content/news/index?pageCount=2'), {
      headers: { Authorization: `Bearer ${token}` }
    }).then(res => res.data.data)
    return data
  }
)

export interface INews {
  [index: string]: any
}

interface IInitialState {
  list: INews[]
}

const initialState: IInitialState = {
  list: []
}

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewsData.fulfilled, (state, action) => {
        state.list = action.payload
      })
  }
})

// export const { setNews } = newsSlice.actions

export default newsSlice.reducer
