import CalendarIcon from "../assets/icons/CalendarIcon"
import HomeIcon from "../assets/icons/HomeIcon"
import NewsIcon from "../assets/icons/NewsIcon"
import ProfileIcon from "../assets/icons/ProfileIcon"

export type TViewValue = 'main' | 'news' | 'calendar' | 'profile'

interface INavData {
  viewValue: TViewValue
  name: string
  icon: any
}

export const navData: INavData[] = [
  {viewValue: 'main', name: 'Главная', icon: HomeIcon},
  {viewValue: 'profile', name: 'Профиль', icon: ProfileIcon},
  {viewValue: 'news', name: 'Новости', icon: NewsIcon},
  {viewValue: 'calendar', name: 'Календарь', icon: CalendarIcon},
]