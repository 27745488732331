import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getGospublicsData } from "../store/slices/gospublicsSlice"
import { RootState } from "../store/store"
import { useToken } from "./useToken"

export const useGospublics = () => {
  const dispatch = useDispatch<any>()
  const eventsData = useSelector((state: RootState) => state.gospublics.list)
  const [token] = useToken()

  useEffect(() => {
    if (eventsData.length > 0) return
    dispatch(getGospublicsData(token))
  }, [dispatch])

  return eventsData
}