import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IInitialState {
  panelState: string
  onboardingState: string | null
}

const initialState: IInitialState = {
  panelState: 'main',
  onboardingState: null
} 

export const panelsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setPanel: (state, action: PayloadAction<string>) => {
      state.panelState = action.payload
    },
    setOnboarding: (state, action: PayloadAction<string | null>) => {
      state.onboardingState = action.payload
    }
  },
})

export const { setPanel, setOnboarding } = panelsSlice.actions

export default panelsSlice.reducer