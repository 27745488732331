import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {getUrl} from "../../api/api";

export const getWeRussiansData = createAsyncThunk(
  'getWeRussiansData',
  async (token: string) => {
    const data = await axios.get(getUrl('/api/v1/content/we-russians/index'), {
      headers: { Authorization: `Bearer ${token}` }
    }).then(res => res.data.data)
    return data
  }
)

export interface IWeRussian {
  [index: string]: any
}

interface IInitialState {
  list: IWeRussian[]
}

const initialState: IInitialState = {
  list: []
}

export const weRussiansSlice = createSlice({
  name: 'weRussians',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWeRussiansData.fulfilled, (state, action) => {
        state.list = action.payload
      })
  }
})

// export const { setNews } = newsSlice.actions

export default weRussiansSlice.reducer
