import { CardGrid, ContentCard, Group, Panel, PanelHeader, PanelHeaderBack } from "@vkontakte/vkui"
import { TMainPanel } from "../MainPanel"
import { ISpace } from "../../../store/slices/spacesSlice"
import axios from "axios"
import { useState, useEffect } from "react"
import { useToken } from "../../../hooks/useToken"
import {getUrl} from "../../../api/api";

interface ISpaceListProps {
  id: TMainPanel
  goToPanel: (value: TMainPanel, id: string) => void
  goBack: (value: TMainPanel) => void
}

const SpaceList = ({ id, goToPanel, goBack }:ISpaceListProps) => {
  const [spaces, setSpaces] = useState<ISpace[]>([])
  const [page, setPage] = useState(1)
  const [fetching, setFetching] = useState(true)
  const token = useToken()

  useEffect(() => {
    if (fetching) {
      console.log('fetching')
      axios.get(getUrl(`/api/v1/spaces/space/index?page=${page}`), {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        if (page > res.data._meta.pageCount) return
        setSpaces([...spaces, ...res.data.data])
        setPage(prev => prev + 1)
      }).finally(() => {
        setFetching(false)
      })
    }
  }, [fetching])

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler)
    return () => {
      document.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  const scrollHandler = (e: any) => {
    if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100) {
      setFetching(true)
    }
  }


  return (
    <Panel id={id}>
      <PanelHeader before={<PanelHeaderBack onClick={() => goBack('main')} />}>Молодёжь 39 </PanelHeader>
      <Group>
        <CardGrid size="l">
          {spaces && (
            <>
            {spaces.length > 0 && spaces.map(space => (
              <ContentCard
                key={space.id}
                src={space.image_url_list[0] ? space.image_url_list[0]?.url_path : '/img/not-image.png'}
                header={space.title}
                caption={space.address}
                onClick={() => goToPanel('spaceDetails', space.id)}
                height={112}
              />
            ))}
            </>
          )}
        </CardGrid>
      </Group>
    </Panel>
  )
}

export default SpaceList
