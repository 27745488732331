import moment from "moment"

type TFormatMode = 'list' | 'details' | 'day'

export const formatDatetime = (date: number, mode: TFormatMode ) => {
  const datetime = new Date(date)

  switch (mode) {
    case 'list':
      return moment(datetime).format('DD MMMM - HH:mm')
    case 'details':
      return moment(datetime).format('DD MMMM YYYY, в HH:mm')
  }

}