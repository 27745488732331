import React, { useState, useEffect } from 'react';
import { FormLayout, FormItem, Input, Button, Panel, Alert } from '@vkontakte/vkui';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {getUrl} from "../../../api/api";
import {useToken} from "../../../hooks/useToken";

const EventForm = ({ onSubmit, eventDetails }: { onSubmit: (data: any) => void, eventDetails: any }) => {
    const userInfo = useSelector((state: any) => state.user.info);
    const [formData, setFormData] = useState({
        fullName: userInfo?.full_name || '',
        phone: userInfo?.phone || '',
        email: '',
        birthDate: '',
    });

    const [token] = useToken()

    useEffect(() => {
        if (userInfo) {
            setFormData((prevData) => ({
                ...prevData,
                fullName: userInfo.full_name || prevData.fullName,
                phone: userInfo.phone || prevData.phone,
            }));
        }
    }, [userInfo]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const [alert, setAlert] = useState<React.ReactNode | null>(null);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const response = await axios.post(getUrl('/api/v1/events/event/will-go'), { event_id: eventDetails.id, ...formData }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (response.status === 200) {
                setAlert(
                    <Alert
                        onClose={() => setAlert(null)}
                        header="Успех"
                        text="Вы успешно зарегистрировались на событие!"
                    />
                );
            } else {
                setAlert(
                    <Alert
                        onClose={() => setAlert(null)}
                        header="Ошибка"
                        text="Ошибка при отправке данных."
                    />
                );
            }
        } catch (error) {
            console.error('Ошибка:', error);
            setAlert(
                <Alert
                    onClose={() => setAlert(null)}
                    header="Ошибка"
                    text="Произошла ошибка при отправке данных."
                />
            );
        }
        onSubmit(formData);
    };

    return (
        <Panel>
            <FormLayout onSubmit={handleSubmit}>
                <FormItem top="ФИО">
                    <Input
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        required
                    />
                </FormItem>
                <FormItem top="Телефон">
                    <Input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />
                </FormItem>
                <FormItem top="Эл. почта">
                    <Input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </FormItem>
                <FormItem top="Дата рождения">
                    <Input
                        type="date"
                        name="birthDate"
                        value={formData.birthDate}
                        onChange={handleChange}
                        required
                    />
                </FormItem>
                <FormItem>
                    <Button size="l" stretched type="submit">Я пойду</Button>
                </FormItem>
            </FormLayout>
            {alert}
        </Panel>
    );
};

EventForm.propTypes = {
    onSubmit: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(Function)]).isRequired,
    eventDetails: PropTypes.object.isRequired
};

export default EventForm;
