import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getNewsData } from "../store/slices/newsSlice"
import { RootState } from "../store/store"
import { useToken } from "./useToken"

export const useNews = () => {
  const dispatch = useDispatch<any>()
  const newsData = useSelector((state: RootState) => state.news.list)
  const [token] = useToken()

  useEffect(() => {
    if (newsData) {
      if (!token) return
      dispatch(getNewsData(token))
    }
  }, [token])

  return newsData
}