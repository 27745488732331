import { Panel, PanelHeader, PanelHeaderBack, Group, Div, Gallery, Paragraph, Title, Switch, SimpleCell, Card, Button, CellButton, ModalRoot, ModalCard, FormItem, Textarea } from "@vkontakte/vkui"
import { Key, useEffect, useState } from "react"
import { getEventsDetail } from "../../../api/requests"
import { TCalendarPanel } from "../CalendarPanel"
import { useToken } from "../../../hooks/useToken"
import { IEvent } from "../../../store/slices/eventsSlice"
import CalendarIcon from "../../../assets/icons/CalendarIcon"
import { formatDatetime } from "../../../utils/fomatDateTime"
import axios from "axios"
import bridge from "@vkontakte/vk-bridge"
import {getUrl} from "../../../api/api";
import EventForm from "./EventForm";

interface IEventsDetails {
  id: TCalendarPanel
  eventId: string
  goBack: () => void
}

type TTheme = 'dark' | 'light'

type TModal = 'feedback' | null

const EventsDetails = ({ id, eventId ,goBack }:IEventsDetails) => {
  const [eventDetails, setEventDetails] = useState<IEvent>()
  const [token] = useToken()
  const [theme, setTheme] = useState<TTheme>('light')
  const [isSelected, setIsSelected] = useState(false)
  const [activeModal, setActiveModal] = useState<TModal>(null)
  const [feedbackValue, setIsFeedbackValue] = useState('')

  useEffect(() => {
    bridge.send('VKWebAppGetConfig')
        .then((data) => {
          setTheme(data.appearance)
        })
    getEventsDetail(eventId, token).then(res => {
      const data = res.data.data
      setEventDetails(data)
      if (new Date(data.date_start * 1000) < new Date()) {
        setIsSelected(data.is_was_present)
      } else {
        setIsSelected(data.is_will_go)
      }
    })
  }, [])

  const sendFeedback = () => {
    axios.post(getUrl('/api/v1/events/event/feedback'), {event_id: eventDetails?.id, comment: feedbackValue}, {
      headers: { Authorization: `Bearer ${token}` },
    })
    setActiveModal(null)
  }

  const handleWillGo = () => {
    if (eventDetails) {
      if (eventDetails.is_will_go) {
        axios.post(getUrl('/api/v1/events/event/will-go-delete'), {event_id: eventDetails.id}, {
          headers: { Authorization: `Bearer ${token}` },
        })
        eventDetails.is_will_go = false
        setIsSelected(false)
      } else {
        axios.post(getUrl('/api/v1/events/event/will-go'), {event_id: eventDetails.id}, {
          headers: { Authorization: `Bearer ${token}` },
        })
        eventDetails.is_will_go = true
        setIsSelected(true)
      }
    }
  }

  const handleWasPresent = () => {
    if (eventDetails) {
      if (eventDetails.is_was_present) {
        axios.post(getUrl('/api/v1/events/event/was-present-delete'), {event_id: eventDetails.id}, {
          headers: { Authorization: `Bearer ${token}` },
        })
        eventDetails.is_was_present = false
        setIsSelected(false)
      } else {
        axios.post(getUrl('/api/v1/events/event/was-present'), {event_id: eventDetails.id}, {
          headers: { Authorization: `Bearer ${token}` },
        })
        eventDetails.is_was_present = true
        setIsSelected(true)
      }
    }
  }

  const shareApp = () => {
    bridge.send('VKWebAppShare', {
      link: 'https://vk.com/app51813130?new=1'
    })
        .then(data => {
          /*
          if (data.result) {
            console.log('Share successful');
          } else {
            console.log('Share failed');
          }

           */
          console.log(data);
        })
        .catch(error => {
          console.error('Error sharing:', error);
        });
  };

  return (
      <Panel id={id} className="panelEvent">
        <PanelHeader before={<PanelHeaderBack onClick={goBack} />}>Молодёжь 39 </PanelHeader>
        <Group>
          {eventDetails !== undefined && (
              <Div>
                <Gallery slideWidth="100%" bullets="light" style={{borderRadius: '16px', marginBottom: '16px'}}>
                  {eventDetails.image_url_list.length > 0 && eventDetails.image_url_list.map((url: { id: Key | null | undefined; url_path: string | undefined; file_original_name: string | undefined }) => (
                      <img
                          key={url.id}
                          data-url={url.url_path}
                          src={url.url_path}
                          style={{ display: 'block', objectFit: 'cover' }}
                          alt={url.file_original_name}
                          height={200}
                      />
                  ))}
                </Gallery>
                <Title level="2" style={{ marginBottom: '8px' }}>
                  {eventDetails.title}
                </Title>
                <Paragraph>
                  <div style={{ padding: '5px', backgroundColor: theme === 'dark' ? "#232324" : '#F5F5F5', borderRadius: '8px', marginBottom: '8px', display: 'inline-block'}}>
                    {eventDetails.type_title}
                  </div>
                </Paragraph>
                <Paragraph style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <CalendarIcon style={{ width: '24px', height: '24px', marginRight: '16px'}} />
                  <span>{formatDatetime(eventDetails.date_start * 1000, 'details')}</span>
                  <span style={{marginLeft: '15px'}}>
                Записалось пользователей:
                <span style={{ fontWeight: 'bold'}}>{eventDetails.is_will_go_count}</span>
              </span>
                </Paragraph>
                <Paragraph>
                  {eventDetails.tags.split(',').map((value: string, index: number) => (
                      <div key={index} style={{ padding: '5px', backgroundColor: theme === 'dark' ? "#232324" : '#F5F5F5', borderRadius: '8px', marginBottom: '8px', display: 'inline-block', marginRight: '5px'}}>
                        {value.trim()}
                      </div>
                  ))}
                </Paragraph>
                <Paragraph>
                  <span dangerouslySetInnerHTML={{__html: eventDetails.description}}></span>
                </Paragraph>
                {eventDetails.vk_video && (
                    <Div>
                      <iframe src={ eventDetails.vk_video } width="100%" height="320"
                              allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameBorder="0"
                              allowFullScreen></iframe>
                    </Div>
                )}
                {new Date(eventDetails.date_start * 1000) < new Date() && (
                    <CellButton onClick={() => setActiveModal('feedback')}>Оставить обратную связь</CellButton>
                )}
              </Div>
          )}
          {eventDetails !== undefined && (
              <>
                <Div>
                  <Card>
                    {new Date(eventDetails.date_start * 1000) < new Date() ? (
                        <SimpleCell Component="label" after={<Switch checked={isSelected} onChange={() => handleWasPresent()} />} style={{borderRadius: '8px'}}>
                          Я посетил
                        </SimpleCell>
                    ) : (
                        <div>
                          {!eventDetails.is_will_go ? (
                              <EventForm
                                  onSubmit={() => {
                                    console.log('send');
                                    setEventDetails((prevDetails) => prevDetails ? { ...prevDetails, is_will_go: true } : prevDetails);
                                    setIsSelected(true);
                                  }}
                                  eventDetails={eventDetails}
                              />
                          ) : (
                              <SimpleCell Component="label" after={<Switch checked={isSelected} onChange={() => handleWillGo()}/>}
                                          style={{borderRadius: '8px'}}>
                                Я пойду
                              </SimpleCell>
                          )}
                        </div>
                    )}
                  </Card>
                </Div>
                <Div>
                  {/*<Button size="l" stretched onClick={shareApp}>
                Рассказать
              </Button>*/}
                  <a style={{textDecoration: 'none'}}
                     href={'https://vk.com/share.php?url=https://vk.com/app51632365_38068314#new=1&title=' + eventDetails.title } target="_blank">
                    <Button size="l" stretched>
                      Рассказать
                    </Button>
                  </a>
                </Div>
              </>
          )}
        </Group>
        <ModalRoot activeModal={activeModal}>
          <ModalCard
              id="feedback"
              onClose={() => setActiveModal(null)}
              header="Оставить обратную связь"
              actions={
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <FormItem style={{ padding: '0 0 20px 0' }}>
                    <Textarea
                        value={feedbackValue}
                        onChange={(e) => setIsFeedbackValue(e.target.value)}
                        placeholder="Поделитесь впечатлениями"
                    />
                  </FormItem>
                  <Button
                      size="l"
                      mode="primary"
                      stretched
                      onClick={sendFeedback}
                  >
                    Отправить
                  </Button>

                </div>
              }
              style={{ zIndex: '10000' }}
          >
          </ModalCard>
        </ModalRoot>
      </Panel>
  )
}

export default EventsDetails
