import { Icon12Verified, Icon28MessageOutline } from "@vkontakte/icons"
import { Avatar, Group, Header, IconButton, Panel, PanelHeader, PanelHeaderBack, SimpleCell } from "@vkontakte/vkui"
import { useGospublics } from "../../hooks/useGospublics"
import { TMainPanel } from "./MainPanel"

interface IGospublics {
  id: TMainPanel
  goBack: (value: TMainPanel) => void
}

const Gospublics = ({ id, goBack }:IGospublics) => {
  const gospublics = useGospublics()

  return (
    <Panel id={id}>
      <PanelHeader before={<PanelHeaderBack onClick={() => goBack('main')} />}>Молодёжь 39</PanelHeader>
      <Group header={<Header mode="secondary">Госпаблики</Header>}>
        {gospublics && (
          <>
            {gospublics.map(item => (
              <a style={{ textDecoration: 'none' }} target="_blank" href={item.url} key={item.id}>
                <SimpleCell before={<Avatar size={40} src={item.image_url_list[0] ? item.image_url_list[0].url_path : ''} />}>
                  {item.title}
                </SimpleCell>
              </a>
            ))}
          </>
        )}
      </Group>
    </Panel>
  )
}

export default Gospublics