import { Icon20ChevronRight } from "@vkontakte/icons"
import { Panel, PanelHeader, Group, Div, RichCell, Avatar, FormItem, Select, Banner, Separator, Calendar, Header, Cell, Badge, CardGrid, Card, Button} from "@vkontakte/vkui"
import { useEffect, useRef, useState } from "react"
import { IUser } from "../../store/slices/userSlice"
import { TProfilePanel } from "./ProfilePanel"
import { IEvent } from "../../store/slices/eventsSlice"
import { formatDatetime } from "../../utils/fomatDateTime"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { calendarMyDates } from "../../utils/calendarMyDate"
import PanelWrapper from "../../components/PanelWrapper"
import Block from "../../components/Block"
import {IAchievement} from "../../store/slices/achievementSlice";
import axios from "axios";
import {getUrl} from "../../api/api";
import {useToken} from "../../hooks/useToken";

interface IProfileInfoProps {
  id: TProfilePanel
  user: IUser | null
  goSettings: (value: TProfilePanel) => void
  goToEvent: (id: string) => void
  goAchievements: (value: TProfilePanel) => void
}

const ProfileInfo = ({ id, goSettings, user, goToEvent, goAchievements }: IProfileInfoProps) => {
  const myEvents = useSelector((state: RootState) => state.events.myList)
  const [events, setEvents] = useState<IEvent[]>()
  const [date, setDate] = useState<any>(() => new Date())
  const [achievements, setAchievements] = useState<IAchievement[]>([])
  const token = useToken()

  const ref = useRef(null)

  const handleChange = (event: any) => {
    setDate(event)
  }

  const update = () => {
    setTimeout(() => {
      calendarMyDates(myEvents)
    }, 0);
  }

  useEffect(() => {
    update()
    setEvents(myEvents.filter(item => {
      console.log(item)
      return (new Date(item.event.date_start * 1000).getMonth() === date.getMonth() && new Date(item.event.date_start * 1000).getDate() === date.getDate())
    }))
  }, [date])

  useEffect(() => {
      axios.get(getUrl('/api/v1/achievements/user-achievement/short'), {
        headers: { Authorization: `Bearer ${token}` }
      })
          .then(res => {
            setAchievements(res.data.data)
          })
  }, []);

  return (
    <Panel id={id}>
      <PanelHeader>Молодёжь 39</PanelHeader>
      <PanelWrapper>
        <Block isTop={true}>
          {user && (
            <Banner
              before={<Avatar size={48} src={user.avatar} />}
              header={user.full_name}
              subheader={user.phone}
              asideMode="expand"
              onClick={() => goSettings('settings')}
              style={{marginTop: '0', paddingTop: '16px'}}
            />
          )}

          {achievements.length != 0 &&
              <Group mode="plain" header={<Header mode="secondary">Достижения</Header>}>
                <CardGrid size="l">
                  <Card>
                    {achievements.map(achievement => (
                        <RichCell
                            before={<Avatar size={40} src={achievement.image} />}
                            subhead="Классификация"
                            text={achievement.name}>
                        </RichCell>
                    ))}
                  </Card>
                  <Button onClick={() => goAchievements('achievementsList')} style={{marginTop: '10px'}} size="l" mode="secondary" stretched>
                    Показать все
                  </Button>
                </CardGrid>
              </Group>
          }

          <Calendar
            getRootRef={ref}
            value={date}
            onChange={handleChange}
            style={{width: '100%', backgroundColor: 'transparent', border: '0', paddingBottom: '0', boxShadow: 'none'}}
            disablePickers={true}
            onNextMonth={() => update()}
            onPrevMonth={() => update()}
          />
        </Block>
        {events !== undefined && (
          <>
            {events.length > 0 && (
              <Block>
                {events.map(item => (
                    <RichCell
                    key={item.event.id}
                    style={{alignItems: 'center'}}
                    subhead={item.event.type_title}
                    caption={formatDatetime(item.event.date_start, 'list')}
                    onClick={() => goToEvent(item.event.id)}
                  >
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      {item.event.title} <Icon20ChevronRight fill="#B8C1CC" />
                    </div>
                  </RichCell>
                ))}
              </Block>
            )}
          </>
        )}
      </PanelWrapper>
    </Panel>
  )
}

export default ProfileInfo
