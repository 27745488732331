const NewsIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="5" y="4" width="18" height="20" rx="3" stroke="#818C99" strokeWidth="1.5"/>
        <rect x="8.17647" y="10" width="11.6471" height="11" rx="2" fill="#818C99"/>
        <rect x="8.17647" y="7" width="5.29412" height="1.5" rx="0.75" fill="#818C99"/>
    </svg>
  );
}

export default NewsIcon
