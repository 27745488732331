interface IHour {
  value: number
  label: string
}

export const getHours = () => {
  let hours: IHour[] = []
  for (let i = 10; i <= 20; i++) {
    hours.push({
      value: i,
      label: `${String(i).padStart(2, '0')}:00`
    })
  }

  return hours
}