const ProfileIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.5392 2.33301H9.46083C5.5175 2.33301 3.16667 4.86467 3.16667 9.11134V18.888C3.16667 22.1663 4.56417 24.418 7.02333 25.2697C7.73833 25.538 8.56167 25.6663 9.46083 25.6663H18.5392C19.4383 25.6663 20.2617 25.538 20.9767 25.2697C23.4358 24.418 24.8333 22.1663 24.8333 18.888V9.11134C24.8333 4.86467 22.4825 2.33301 18.5392 2.33301ZM23.2083 18.888C23.2083 21.3847 22.2983 22.9597 20.4675 23.613C19.4167 21.3847 16.925 19.798 14 19.798C11.075 19.798 8.59417 21.373 7.5325 23.613H7.52167C5.7125 22.983 4.79167 21.3963 4.79167 18.8997V9.11134C4.79167 5.82134 6.40583 4.08301 9.46083 4.08301H18.5392C21.5942 4.08301 23.2083 5.82134 23.2083 9.11134V18.888Z" fill="#818C99"/>
        <path d="M14 9.33301C11.855 9.33301 10.1217 11.1997 10.1217 13.5097C10.1217 15.8197 11.855 17.698 14 17.698C16.145 17.698 17.8783 15.8197 17.8783 13.5097C17.8783 11.1997 16.145 9.33301 14 9.33301Z" fill="#818C99"/>
    </svg>
  );
}

export default ProfileIcon
