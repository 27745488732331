import { Panel, View } from "@vkontakte/vkui"
import { useState } from "react"
import NewsList from "./NewsList"
import NewsDetails from "./NewsDetails"

interface INewsPanelProps {
  id: string
}

export type TNewsPanel = 'list' | 'details'

const NewsPanel = ({ id }: INewsPanelProps) => {
  const [activePanel, setActivePanel] = useState<TNewsPanel>('list')
  const [newsDetailsId, setNewsDetailsId] = useState<string>('')

  const openNewsDetails = (id: string) => {
    setNewsDetailsId(id)
    setActivePanel('details')
  }

  return (
    <Panel id={id}>
      <View activePanel={activePanel}>
        <NewsList id="list" handleClick={openNewsDetails} />
        <NewsDetails id="details" newsId={newsDetailsId} goBack={() => setActivePanel('list')} />
      </View>
    </Panel>
  )
}

export default NewsPanel
