function get(name: any){
    if(name=(new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(location.search))
        return decodeURIComponent(name[1]);
}

export const getUrl = (url: string) => {
    console.log(process.env)
    console.log(process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_HOST)

    console.log('https://' + process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_PORT + url);

    return 'https://' + process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_PORT + url;
}
