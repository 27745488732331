import { Button, CellButton, CustomSelect, Div, FixedLayout, FormItem, FormLayout, Group, Panel, PanelHeader, PanelHeaderBack, Select } from "@vkontakte/vkui"
import { TCalendarPanel } from "../../Calendar/CalendarPanel"
import { useEffect, useState } from "react"
import { useFilters } from "../../../hooks/useFilters"
import { useDispatch } from "react-redux"
import { setLevel, setType } from "../../../store/slices/filtersSlice"
import { TMainPanel } from "../MainPanel"
import axios from "axios"
import { getFilterEvents } from "../../../api/requests"
import { useToken } from "../../../hooks/useToken"
import { setEvents } from "../../../store/slices/eventsSlice"

interface IEventsFilterProps {
  id: TMainPanel
  goBack: (value: TMainPanel) => void
}

const EventsFilter = ({ id, goBack }:IEventsFilterProps) => {
  const filters = useFilters()
  const dispatch = useDispatch()
  const [token] = useToken()

  const handleSubmit = async () => {
    await getFilterEvents(filters.selectedType ? filters.selectedType : '', filters.selectedLevel ? filters.selectedLevel : '', token)
      .then(res => dispatch(setEvents(res.data.data)))
    goBack('eventsList')
  }

  const resetFilters = () => {
    dispatch(setType(undefined))
    dispatch(setLevel(undefined))
    handleSubmit()
    goBack('eventsList')
  }

  return (
    <Panel id={id} >
      <PanelHeader before={<PanelHeaderBack onClick={() => goBack('eventsList')} />}>Фильтры</PanelHeader>  
      <Group>
        <FormLayout>
          <FormItem top="Направление" htmlFor="type">
            <CustomSelect 
              id="type"
              value={filters.selectedType}
              placeholder="Выбрать направление"
              options={filters.type.map(item => ({
                label: item.title,
                value: String(item.id),
              }))}
              onChange={(e) =>  dispatch(setType(e.target.value))}
            />
          </FormItem>
          <FormItem top="Уровень" htmlFor="level">
            <CustomSelect 
              id="level"
              value={filters.selectedLevel}
              placeholder="Выбрать уровень"
              options={filters.level.map(item => ({
                label: item.title,
                value: String(item.id),
              }))}
              onChange={(e) => dispatch(setLevel(e.target.value))}
              after={<Button>Clear</Button>}
            />
          </FormItem>
        </FormLayout>
        <CellButton centered onClick={resetFilters}>
          Очистить всё
        </CellButton>
        <FixedLayout vertical="bottom" filled>
          <Div>
            <Button size="l" stretched onClick={handleSubmit}>
              Показать результаты
            </Button>
          </Div>
        </FixedLayout>
      </Group>
    </Panel>
  )
}

export default EventsFilter