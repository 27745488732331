import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../store/store"
import { useEffect } from "react"

export const useToken = () => {
  const token = useSelector<RootState, string>(state => state.user.token)
  const dispatch = useDispatch<any>()

  return [token]
  
}