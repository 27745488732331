import { ModalRoot, ModalCard, Gallery, Avatar, Title, FormItem, Button, Subhead } from "@vkontakte/vkui"
import { useState } from "react";
import { onboardings } from "../data/onboardings";

interface IOnboarding {
  activeModal: string | null
  onClose: () => void
}

const Onboarding = ({ activeModal, onClose }: IOnboarding) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const resetModal = () => {
    onClose()
    setTimeout(() => {
      setSlideIndex(0)
    }, 500);
  }

  return (
    <ModalRoot activeModal={activeModal}>
      <ModalCard
        id={'onboarding'}
        onClose={onClose}
      >
        <Gallery 
          slideWidth="100%" 
          bullets="light"
          isDraggable={false} 
          slideIndex={slideIndex}
        >
          {onboardings.map(item => (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} key={item.id}>
              <Avatar
                size={72}
                src={item.img}
                style={{marginBottom: '16px'}}
              />
              <Title level="2" style={{ marginBottom: 16 }}>
                {item.title}
              </Title>
              <div style={{textAlign: 'center', marginBottom: '45px'}}>
                <Subhead>{item.descr}</Subhead>
              </div>
            </div>
          ))}
        </Gallery>
        <FormItem>
          {slideIndex + 1 < onboardings.length ? (
            <Button size="l" stretched onClick={() => setSlideIndex(slideIndex + 1)}>
              Далее 
            </Button>
          ) : (
            <Button size="l" stretched onClick={() => resetModal()}>
              Понятно
            </Button>
          )}
        </FormItem>
      </ModalCard> 
    </ModalRoot>
  )
}

export default Onboarding