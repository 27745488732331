import { Icon16ChevronOutline, Icon20ChevronRightOutline } from "@vkontakte/icons"
import { Avatar, Button, Div, FormItem, FormLayout, Group, Input, Panel, PanelHeader, PanelHeaderBack, RichCell, Select, View } from "@vkontakte/vkui"
import { useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import ProfileInfo from "./ProfileInfo"
import ProfileSettings from "./ProfileSettings"
import EventsDetails from "../Calendar/Events/EventDetails"
import AchievementsList from "../Achievements/AchievementsList";

interface IProfilePanelProps {
  id: string
}

export type TProfilePanel = 'info' | 'settings' | 'eventDetails' | 'achievementsList'

const ProfilePanel = ({ id }: IProfilePanelProps) => {
  const [activePanel, setActivePanel] = useState<TProfilePanel>('info')
  const userData = useSelector((state: RootState) => state.user.info)
  const [eventId, setEventId] = useState('')

  const goToEvent = (id: string) => {
    setEventId(id)
    setActivePanel('eventDetails')
  }

  return (
    <Panel id={id}>
      <View activePanel={activePanel}>
        <ProfileInfo id="info" goSettings={setActivePanel} user={userData} goToEvent={goToEvent} goAchievements={setActivePanel} />
        <ProfileSettings id="settings" goBack={setActivePanel} user={userData} />
        <EventsDetails id="eventDetails" goBack={() => setActivePanel('info')} eventId={eventId} />
        <AchievementsList id='achievementsList' goBack={() => setActivePanel('info')} />
      </View>
    </Panel>
  )
}

export default ProfilePanel