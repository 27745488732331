import { Panel, PanelHeader, Div, Calendar } from "@vkontakte/vkui"
import EventsList from "./EventsList"
import { useEffect, useRef, useState } from "react"
import moment from 'moment';
import 'moment/locale/ru'
import PanelWrapper from "../../../components/PanelWrapper";
import Block from "../../../components/Block";
import { IEvent } from "../../../store/slices/eventsSlice";
import { useEvents } from "../../../hooks/useEvents";
moment.locale('ru')
import './custom.calendar.css'
import { calendarDates } from "../../../utils/calendarDate";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";


interface IEventsProps {
  id: string
  goToDetails: (id:string) => void
}

const Events = ({ id, goToDetails }:IEventsProps) => {
  const events = useEvents()
  const myEvents = useSelector((state: RootState) => state.events.myList)
  const [dateEvents, setDateEvents] = useState<IEvent[]>([])
  const [date, setDate] = useState<any>(() => new Date())
  const ref = useRef(null)

  const handleChange = (event: any) => {
    setDate(event)
  }

  const update = () => {
    setTimeout(() => {
      calendarDates(events, myEvents)
    }, 0);
  }

  useEffect(() => {
    setDateEvents(events.filter(item => {
      return (new Date(item.date_start * 1000).getMonth() === date.getMonth() && new Date(item.date_start * 1000).getDate() === date.getDate())
    }))

    update()
  }, [date])

  return (
    <Panel id={id} >
      <PanelHeader style={{backgroundColor: '#2d2d2e'}}>Молодёжь 39</PanelHeader>
      <PanelWrapper>
        <Block isTop={true} >
          <Calendar
            getRootRef={ref}
            value={date}
            onChange={handleChange}
            style={{width: '100%', backgroundColor: 'transparent', border: '0', paddingBottom: '0', boxShadow: 'none'}}
            disablePickers={true}
            onNextMonth={() => update()}
            onPrevMonth={() => update()}
          />
        </Block>
        <EventsList
          goToDetails={goToDetails}
          eventsData={dateEvents}
        />
      </PanelWrapper>
    </Panel>
  )
}

export default Events
