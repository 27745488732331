import moment from "moment"
import { IEvent } from "../store/slices/eventsSlice"

export const calendarDates = (events: IEvent[], myEvents: IEvent[]) => {
  const importand = events.find(item => item.is_important === true)
  let importandDate = ''
  if (importand) {
    importandDate = moment(new Date(importand.date_start * 1000)).format('dddd, D MMMM YYYY г.')
  }
  console.log(importandDate)
  const monthDates: NodeListOf<HTMLDivElement> | null = document.querySelectorAll('.vkuiCalendarDay')
  const eventDates = events.map(item => moment(new Date(item.date_start * 1000)).format('dddd, D MMMM YYYY г.'))
  for (let i = 0; i < eventDates.length; i++) {
    monthDates.forEach(item => {
      if (item.getAttribute('aria-label') === eventDates[i]) {
        const container = item.querySelector('.vkuiCalendarDay__inner')
        const bageContainer = document.createElement('div')
        const bage = document.createElement('span')

        let hasClass: boolean = false;

        for (let a = 0; a < myEvents.length; a++) {
          if (moment(new Date(myEvents[a].event.date_start * 1000)).format('dddd, D MMMM YYYY г.') === eventDates[i] ) {
            bage.classList.add('bage-yellow')
            hasClass = true;
          }
          else {
            bage.classList.add('bage-green')
            hasClass = true;
          }

          if (importandDate === eventDates[i]) {
            bage.classList.add('bage-red')
            hasClass = true;
          }
        }

        if(!hasClass) {
          bage.classList.add('bage-green')
        }

        bageContainer.append(bage)
        bageContainer.classList.add('bages')
        if (container) {
          container.append(bageContainer)
        }
      }
    })
  }
}
