import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import bridge from '@vkontakte/vk-bridge'
import { getFullAge } from '../../utils/getFullAge'
import axios from 'axios'
import {getUrl} from "../../api/api";

export const getUserData = createAsyncThunk(
  'getUserData',
  async () => {
    const vkUser = await bridge.send('VKWebAppGetLaunchParams').then(res => res)
    let userInfo: IUser | null = null
    let token: string | null = null
    let isNew: boolean = false

    if (vkUser) {
      // const str = `vk_access_token_settings=${vkUser.vk_access_token_settings}&vk_app_id=${vkUser.vk_app_id}&vk_are_notifications_enabled=${vkUser.vk_are_notifications_enabled}&vk_is_app_user=${vkUser.vk_is_app_user}&vk_language=${vkUser.vk_language}&vk_platform=${vkUser.vk_platform}&vk_user_id=${vkUser.vk_user_id}&sign=${vkUser.sign}`

      const auth = await axios.get(getUrl(`/api/v1/user/auth/vk-id?qtpx=` + window.btoa(window.location.href))).then(res => res.data.data)
      token = auth.token
      isNew = auth.is_new

      console.log(auth.is_new);

      const user = await bridge.send('VKWebAppGetUserInfo', { user_id: vkUser.vk_user_id }).then(res => res)

      console.log(user);

      if (auth.is_new) {

        console.log('in new');

        userInfo = {
          id: auth.user.id,
          full_name: `${user.first_name} ${user.last_name}`,
          // city: user.city.title,
          // age: isNaN(Number(getFullAge(user.bdate))) ? '' : getFullAge(user.bdate),
          age: "1",
          city: "",
          vk_user_id: auth.user.vk_user_id,
        }

        // if(user.bdate_visibility)

        console.log(userInfo);

        /*try {
          const phoneNumber = await bridge.send('VKWebAppGetPhoneNumber').then(data => data.phone_number)
          if (userInfo) {
            userInfo.phone = phoneNumber
          }
        } catch (error) {
          console.log('Пользователй запретил использование номера')
        }*/



        axios.post(getUrl(`/api/v1/user/user/update?id=${auth.user.id}`), userInfo, {
          headers: { Authorization: `Bearer ${token}` },
        }).then(res => {
          console.log(res);
        })

        userInfo.avatar = user.photo_max_orig


      } else {
        userInfo = auth.user
        if (userInfo) {
          userInfo.avatar = user.photo_max_orig
        }
      }
    }

    return {userInfo, token, isNew}
  }
)

export interface IUser {
  id: number
  full_name: string
  age: string | undefined
  city: string
  phone?: string
  vk_user_id: number
  login?: string | undefined
  avatar?: string
}

interface IInitialState {
  info: IUser | null
  token: string
  isNew: boolean
}

const initialState: IInitialState = {
  info: null,
  token: '',
  isNew: false,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserData.fulfilled, (state, action) => {
        if (action.payload) {
          state.info = action.payload.userInfo
          if (action.payload.token !== null)
          state.token = action.payload.token
          state.isNew = action.payload.isNew
        }
      })
  }
})

// export const { setToken } = userSlice.actions

export default userSlice.reducer
