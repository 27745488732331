import {
    Avatar,
    Button,
    Card,
    CardGrid,
    ContentCard,
    Group,
    Header,
    Panel,
    PanelHeader,
    PanelHeaderBack,
    RichCell,
    Div,
    ModalRoot,
    ModalPage,
    ModalPageHeader,
    PanelHeaderClose,
    PanelHeaderButton,
    SplitLayout,
    Title,
    Text,
    Image
} from "@vkontakte/vkui"
import { useEffect, useState } from "react"
import { useToken } from "../../hooks/useToken"
import axios from "axios"
import {getUrl} from "../../api/api";
import {IAchievement} from "../../store/slices/achievementSlice";
import {TProfilePanel} from "../Profile/ProfilePanel";
import { Icon20ChevronRight, Icon24Dismiss } from '@vkontakte/icons';

interface IAchievementsListProps {
  id: TProfilePanel
  goBack: (id: string) => void
}

const NewsList = ({ id, goBack }:IAchievementsListProps) => {
  const [achievements, setAchievements] = useState<IAchievement[]>([])
  const token = useToken()
  const [activeModal, setActiveModal] = useState('');
  const [activeAchievement, setActiveAchievement] = useState<IAchievement>()
  // const newsData = useNews()

  useEffect(() => {
    axios.get(getUrl('/api/v1/achievements/user-achievement/index'), {
      headers: { Authorization: `Bearer ${token}` }
    })
        .then(res => {
          setAchievements(res.data.data)
        })
  }, [])

    const openAchievement = (achievement: IAchievement) => {
        setActiveAchievement(achievement)
        setActiveModal('ah')
    }

    const modal = (
        <ModalRoot activeModal={activeModal} onClose={() => {setActiveModal('')}}>
            <ModalPage
                id='ah'
                settlingHeight={100}
                header={
                    <ModalPageHeader
                        before={
                            <PanelHeaderClose onClick={() => {setActiveModal('')}} />
                        }
                    >
                        {activeAchievement && activeAchievement.name}
                    </ModalPageHeader>
                }
            >
                <Group>
                    <Div>
                        <Image src='/img/not-image.png' style={{ width: '100%' }} />
                        <Title level="3" style={{marginTop: '10px', marginBottom: '10px'}}>
                            {activeAchievement && activeAchievement.name}
                        </Title>
                        <Text>
                            {activeAchievement && activeAchievement.description}
                        </Text>
                        <Button style={{marginTop: '10px'}} size="l" mode="secondary" stretched appearance={'accent'}>
                            Поделиться
                        </Button>
                    </Div>
                </Group>

            </ModalPage>
        </ModalRoot>
    )

  return (
      <SplitLayout modal={modal}>
        <Panel id={id}>
            <PanelHeader before={<PanelHeaderBack onClick={() => goBack('info')} />}>Молодёжь 39</PanelHeader>
            <Group mode="plain" header={<Header mode="secondary">Достижения</Header>}>

            <CardGrid size="l">
                {achievements.map(achievement => (
                    <Div style={{width: '100%', padding: 0}}>
                        <RichCell
                            onClick={() => {openAchievement(achievement)}}
                            before={<Avatar size={40} src={achievement.image} />}
                            subhead="Классификация"
                            afterCaption={<Icon20ChevronRight />}
                            text={achievement.name}>
                        </RichCell>
                    </Div>
                ))}
            </CardGrid>
          </Group>
        </Panel>
      </SplitLayout>
  )
}

export default NewsList
