import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {getUrl} from "../../api/api";

export const getSpacesData = createAsyncThunk(
  'getSpacesData',
  async (token: string) => {
    const data = await axios.get(getUrl('/api/v1/spaces/space/index'), {
      headers: { Authorization: `Bearer ${token}` }
    }).then(res => res.data.data)
    return data
  }
)

export interface ISpace {
  [index: string]: any
}

interface IInitialState {
  list: ISpace[]
}

const initialState: IInitialState = {
  list: []
}

export const spacesSlice = createSlice({
  name: 'spaces',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSpacesData.fulfilled, (state, action) => {
        state.list = action.payload
      })
  }
})

// export const { setNews } = newsSlice.actions

export default spacesSlice.reducer
