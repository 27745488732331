import axios from "axios"
import {getUrl} from "./api";

export const getNewsDetails = async (id: string, token: string) => {
  return await axios.get(getUrl(`/api/v1/content/news/view?id=${id}`), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getEventsDetail = async (id: string, token: string) => {
  return await axios.get(getUrl(`/api/v1/events/event/view?id=${id}`), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getSpaceDetails = async (id: string, token: string) => {
  return await axios.get(getUrl(`/api/v1/spaces/space/view?id=${id}`), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getWeRussiansDetails = async (id: string, token: string) => {
  return await axios.get(getUrl(`/api/v1/content/we-russians/view?id=${id}`), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getFilterEvents = async (type: string, level: string, token: string) => {
  return await axios.get(getUrl(`/api/v1/events/event/index?type_id=${type}&level_id=${level}&sort=-created_at`), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}
