import bridge from "@vkontakte/vk-bridge"
import { useEffect, useState } from "react"

interface IBlock {
  children: React.ReactNode
  isTop?: boolean
}

type TTheme = 'dark' | 'light'

const Block = ({ children, isTop = false }:IBlock) => {
  const [theme, setThem] = useState<TTheme>('light')
  useEffect(() => {
    bridge.send('VKWebAppGetConfig')
    .then((data) => { 
      setThem(data.appearance)
    })
  }, [])

  return (
    <div style={isTop ? (theme === 'light' ? topBlockStyle: topBlockDarkStyle) : (theme === 'light' ? blockStyles : blockStylesDark)}>
      {children}
    </div>
  )
}

const blockStyles = {
  backgroundColor: '#FFFFFF',
  borderRadius: '16px',
  marginBottom: '8px',
  padding: '12px 0'
}

const blockStylesDark = {
  backgroundColor: '#19191a',
  borderRadius: '16px',
  marginBottom: '8px',
  padding: '12px 0'
} 

const topBlockStyle = {
  backgroundColor: '#FFFFFF',
  borderBottomRightRadius: '16px',
  borderBottomLeftRadius: '16px',
  marginBottom: '8px',
  paddingBottom: '16px',
}

const topBlockDarkStyle = {
  backgroundColor: '#19191a',
  borderBottomRightRadius: '16px',
  borderBottomLeftRadius: '16px',
  marginBottom: '8px',
  paddingBottom: '16px',
}

export default Block