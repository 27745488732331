import { CardGrid, ContentCard, Group, Panel, PanelHeader } from "@vkontakte/vkui"
import { useSelector } from "react-redux"
import { useNews } from "../../hooks/useNews"
import { RootState } from "../../store/store"
import { formatDatetime } from "../../utils/fomatDateTime"
import { TNewsPanel } from "./NewsPanel"
import { useEffect, useState } from "react"
import { useToken } from "../../hooks/useToken"
import axios from "axios"
import { INews } from "../../store/slices/newsSlice"
import {getUrl} from "../../api/api";

interface INewsListProps {
  id: TNewsPanel
  handleClick: (id: string) => void
}

const NewsList = ({ id, handleClick }:INewsListProps) => {
  const [newsData, setNewsData] = useState<INews[]>([])
  const [page, setPage] = useState(1)
  const [fetching, setFetching] = useState(true)
  const token = useToken()
  // const newsData = useNews()

  useEffect(() => {
    if (fetching) {
      console.log('fetching')
      axios.get(getUrl(`/api/v1/content/news/index?page=${page}&sort=-created_at`), {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        if (page > res.data._meta.pageCount) return
        setNewsData([...newsData, ...res.data.data])
        setPage(prev => prev + 1)
      }).finally(() => {
        setFetching(false)
      })
    }
  }, [fetching])

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler)
    return () => {
      document.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  const scrollHandler = (e: any) => {
    if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100) {
      setFetching(true)
    }
  }

  return (
    <Panel id={id}>
      <PanelHeader>Молодёжь 39</PanelHeader>
      <Group>
        <CardGrid size="l">
          {newsData.length > 0 && newsData.map(news => (
            <ContentCard
              key={news.id}
              src={news.image_url_list[0] ? news.image_url_list[0].url_path : '/img/not-image.png'}
              header={news.title}
              caption={formatDatetime(news.date * 1000, 'details')}
              onClick={() => handleClick(news.id)}
              height={112}
            />
          ))}
        </CardGrid>
      </Group>
    </Panel>
  )
}

export default NewsList
