import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getFiltersData } from "../store/slices/filtersSlice"
import { RootState } from "../store/store"
import { useToken } from "./useToken"

export const useFilters = () => {
  const dispatch = useDispatch<any>()
  const filtersData = useSelector((state: RootState) => state.filters)
  const [token] = useToken()

  useEffect(() => {
    if (filtersData) {
      if ((filtersData.type.length > 0 && filtersData.level.length > 0) || !token) return
      dispatch(getFiltersData(token))
    }
  }, [token])

  return filtersData
}