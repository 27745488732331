import { Card, Panel, PanelHeader, PanelHeaderBack, Group, Gallery, Div, Calendar, Cell, Header, Checkbox, Radio, Title, Paragraph, Caption, Subhead, CustomSelect, Button, FixedLayout, ModalCard, ModalRoot, Avatar, FormItem, Textarea, Input } from "@vkontakte/vkui";
import { CellCheckbox } from "@vkontakte/vkui/dist/components/Cell/CellCheckbox/CellCheckbox";
import { Key, useEffect, useState } from "react";
import Block from "../../../components/Block";
import PanelWrapper from "../../../components/PanelWrapper";
import NewsDetails from "../../News/NewsDetails";
import { TMainPanel } from "../MainPanel";
import { getSpaceDetails } from "../../../api/requests";
import { useToken } from "../../../hooks/useToken";
import { ISpace } from "../../../store/slices/spacesSlice";
import { CalendarTime } from "@vkontakte/vkui/dist/components/CalendarTime/CalendarTime";
import users from "@vkontakte/icons/dist/typings/12/users";
import { getHours } from "../../../utils/getHours";
import axios from "axios";
import bridge from "@vkontakte/vk-bridge";
import { getUrl } from "../../../api/api";


interface ISpaceDetailsProps {
    id: TMainPanel;
    spaceId: string;
    goBack: (value: TMainPanel) => void;
}

type TModal = 'success' | 'failed' | null;
type TTheme = 'dark' | 'light';

const SpaceDetails = ({ id, spaceId, goBack }: ISpaceDetailsProps) => {
    const [formState, setFormState] = useState({
        fullName: '',
        phone: '',
        email: '',
        eventName: '',
        organizer: '',
        peopleCount: '',
        age: '',
        eventDescription: '',
        publicationLink: '',
        publicationPlanned: false,
        comment: '',
        date: new Date() as Date,
        timeStart: 0,
        timeEnd: 0
    });
    const [activeModal, setActiveModal] = useState<TModal>(null);



    const [spaceDetails, setSpaceDetails] = useState<ISpace>();
    const [token] = useToken();
    const [theme, setTheme] = useState<TTheme>('light');
    const [feedbackValue, setIsFeedbackValue] = useState('');

    useEffect(() => {
        bridge.send('VKWebAppGetConfig').then((data) => {
            setTheme(data.appearance);
        });
        getSpaceDetails(spaceId, token).then((res) => setSpaceDetails(res.data.data));
    }, []);

    const setTimeStartEnd = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setFormState({ ...formState, timeStart: parseInt(event.target.value) })
        // setFormState({ ...formState, timeEnd: parseInt(event.target.value) + 2 })
    };

    const onBooking = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const year = formState.date.getFullYear();
        const month = formState.date.getMonth();
        const day = formState.date.getDate();
        const datetimeStart = new Date(year, month, day, formState.timeStart)?.getTime();
        const datetimeEnd = new Date(year, month, day, formState.timeEnd)?.getTime();

        if (spaceDetails) {
            try {
                axios.post(
                    getUrl('/api/v1/spaces/space/booking'),
                    {
                        date: formState.date.toLocaleDateString('en-CA'),
                        space_id: spaceDetails.id,
                        time_start: datetimeStart / 1000,
                        time_end: datetimeEnd / 1000,
                        comment: feedbackValue,
                        full_name: formState.fullName,
                        phone: formState.phone,
                        email: formState.email,
                        event_name: formState.eventName,
                        organizer: formState.organizer,
                        people_count: formState.peopleCount,
                        age: formState.age,
                        event_description: formState.eventDescription,
                        publication_link: formState.publicationLink,
                        publication_planned: formState.publicationPlanned,
                    },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );



                setIsFeedbackValue('');
                setFormState({ ...formState, date: new Date() })

                setActiveModal('success');
            } catch (err) {
                console.log(err);
            }
        }
    };

    return (
        <Panel id={id}>
            <PanelHeader before={<PanelHeaderBack onClick={() => goBack('spaces')} />}>Молодёжь 39</PanelHeader>
            <PanelWrapper>
                <Block isTop={true}>
                    <Div>
                        <Gallery slideWidth="100%" bullets="light" style={{ borderRadius: '16px', marginBottom: '16px' }}>
                            {spaceDetails?.image_url_list.length > 0 &&
                                spaceDetails?.image_url_list.map((url: { id: Key | null | undefined; url_path: string | undefined; file_original_name: string | undefined }) => (
                                    <img
                                        key={url.id}
                                        src={url.url_path}
                                        style={{ display: 'block', objectFit: 'cover' }}
                                        alt={url.file_original_name}
                                        height={200}
                                    />
                                ))}
                        </Gallery>
                        <Subhead style={{ textTransform: 'uppercase', fontSize: '11px' }}>{spaceDetails?.type}</Subhead>
                        <Title level="2" style={{ marginBottom: '8px' }}>
                            {spaceDetails?.title}
                        </Title>
                        <Paragraph dangerouslySetInnerHTML={{ __html: spaceDetails?.description }} />
                        <Paragraph>
                            <div
                                style={{
                                    padding: '5px',
                                    backgroundColor: theme === 'dark' ? '#232324' : '#F5F5F5',
                                    borderRadius: '8px',
                                    marginBottom: '8px',
                                }}
                            >
                                {spaceDetails?.booking_availability}
                            </div>
                        </Paragraph>
                    </Div>
                </Block>
                <Card style={{ marginBottom: '16px' }}>
                    <form onSubmit={onBooking}>
                        <Block>
                            <FormItem top="Имя и Фамилия">
                                <Input type="text" name="fullName" required value={formState.fullName} onChange={(e) => setFormState({ ...formState, fullName: e.target.value })} />
                            </FormItem>
                            <FormItem top="Телефон">
                                <Input type="tel" name="phone" required placeholder="+79999999999" value={formState.phone} onChange={(e) => setFormState({ ...formState, phone: e.target.value })} />
                            </FormItem>
                            <FormItem top="Эл. почта">
                                <Input type="email" name="email" required value={formState.email} onChange={(e) => setFormState({ ...formState, email: e.target.value })} />
                            </FormItem>
                            <FormItem top="Название мероприятия">
                                <Input type="text" name="eventName" required value={formState.eventName} onChange={(e) => setFormState({ ...formState, eventName: e.target.value })} />
                            </FormItem>
                            <FormItem top="Организатор">
                                <Input type="text" name="organizer" required value={formState.organizer} onChange={(e) => setFormState({ ...formState, organizer: e.target.value })} />
                            </FormItem>
                            <FormItem top="Количество человек">
                                <Input type="number" name="peopleCount" required value={formState.peopleCount} onChange={(e) => setFormState({ ...formState, peopleCount: e.target.value })} />
                            </FormItem>
                            <FormItem top="Возраст">
                                <Input type="text" name="age" required value={formState.age} onChange={(e) => setFormState({ ...formState, age: e.target.value })} />
                            </FormItem>
                            <FormItem top="Подробное описание мероприятия">
                                <Textarea name="eventDescription" required value={formState.eventDescription} onChange={(e) => setFormState({ ...formState, eventDescription: e.target.value })} />
                            </FormItem>
                            <FormItem top="Комментарий">
                                <Textarea value={feedbackValue} onChange={(e) => setFormState({ ...formState, comment: e.target.value })} placeholder="Комментарий" name="comment" />
                            </FormItem>
                            <FormItem top="Планируется ли публикация в СМИ">
                                <Checkbox checked={formState.publicationPlanned} onChange={(e) => setFormState({ ...formState, publicationPlanned: e.target.checked })}>Планируется публикация</Checkbox>
                                <Input type="text" name="publicationLink" placeholder="Ссылка на публикацию (если да)" value={formState.publicationLink} onChange={(e) => setFormState({ ...formState, publicationLink: e.target.value })} />
                            </FormItem>
                            <Calendar
                                value={formState.date ?? new Date()}
                                onChange={(value) => setFormState({ ...formState, date: value as Date })}
                                style={{ width: '100%', backgroundColor: 'transparent', boxShadow: '0', border: 'none', padding: '0 12px' }}
                                disablePickers={true}
                                shouldDisableDate={(date) => date < new Date()}
                            />
                            <Div style={{ display: 'flex', alignItems: 'center' }}>
                                <CustomSelect
                                    id="timeStart"
                                    placeholder="Не выбрано"
                                    value={formState.timeStart}
                                    onChange={(e) => setTimeStartEnd(e)}
                                    options={getHours()}
                                    autoHideScrollbar
                                />
                                <span style={{ margin: '0 15px' }}>-</span>
                                <CustomSelect
                                    id="timeEnd"
                                    value={formState.timeEnd}
                                    onChange={(e) => setFormState({ ...formState, timeEnd: parseInt(e.target.value) })}
                                    placeholder="Не выбрано"
                                    options={getHours()}
                                    autoHideScrollbar
                                />
                            </Div>
                            <Div style={{ width: '100%', boxSizing: 'border-box' }}>
                                <Button size="l" stretched type="submit">
                                    Забронировать
                                </Button>
                            </Div>
                        </Block>
                    </form>
                </Card>
            </PanelWrapper>
            <ModalRoot activeModal={activeModal}>
                <ModalCard
                    id="success"
                    onClose={() => setActiveModal(null)}
                    icon={<Avatar src={'/img/success-booking.png'} size={72} />}
                    header="Заявка принята"
                    actions={
                        <Button size="l" mode="primary" stretched onClick={() => setActiveModal(null)}>
                            Понятно
                        </Button>
                    }
                    style={{ zIndex: '10000' }}
                ></ModalCard>
            </ModalRoot>
        </Panel>
    );
};

export default SpaceDetails;
