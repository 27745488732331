import { configureStore } from '@reduxjs/toolkit'
import userReducer from './slices/userSlice'
import newsReducer from './slices/newsSlice'
import eventsReducer from './slices/eventsSlice'
import gospublicsReducer from './slices/gospublicsSlice'
import panelsReducer from './slices/panelsSlice'
import spacesReducer from './slices/spacesSlice'
import weRussiansReducer from './slices/weRussiansSlice'
import filtersReducer from './slices/filtersSlice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    panels: panelsReducer,
    news: newsReducer,
    events: eventsReducer,
    gospublics: gospublicsReducer,
    spaces: spacesReducer,
    weRussians: weRussiansReducer,
    filters: filtersReducer,
  },
  devTools: true,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch