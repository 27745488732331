import moment from "moment"
import { IEvent } from "../store/slices/eventsSlice"

export const calendarMyDates = (events: IEvent[]) => {
  const monthDates: NodeListOf<HTMLDivElement> | null = document.querySelectorAll('.vkuiCalendarDay')
  const eventDates = events.map(item => moment(new Date(item.event.date_start * 1000)).format('dddd, D MMMM YYYY г.'))
  console.log(eventDates)
  for (let i = 0; i < eventDates.length; i++) {
    monthDates.forEach(item => {
      if (item.getAttribute('aria-label') === eventDates[i]) {
        const container = item.querySelector('.vkuiCalendarDay__inner')
        const bageContainer = document.createElement('div')
        const bage = document.createElement('span')
        if (new Date(events[i].event.date_start * 1000) >= new Date) {
          bage.classList.add('bage-green')
        } else if (new Date(events[i].event.date_start * 1000) < new Date && events[i].event.is_was_present === true) {
          bage.classList.add('bage-yellow')
        } else {
          bage.classList.add('bage-gray')
        }
        bageContainer.append(bage)
        bageContainer.classList.add('bages')
        if (container) {
          container.append(bageContainer)
        }
      } 
    })
  }
}