import React, { useEffect } from 'react';
import { View, AdaptivityProvider, AppRoot, ConfigProvider, SplitLayout, SplitCol } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

import Navigation from './components/Navigation';
import MainPanel from './panels/Main/MainPanel';
import ProfilePanel from './panels/Profile/ProfilePanel';
import { useDispatch } from 'react-redux';
import { getUserData } from './store/slices/userSlice';
import NewsPanel from './panels/News/NewsPanel';
import CalendarPanel from './panels/Calendar/CalendarPanel';

const App = () => {
	const dispatch = useDispatch<any>()
	
	useEffect(() => {
		dispatch(getUserData())
	}, [])

	return (
		<ConfigProvider>
			<AdaptivityProvider>
				<AppRoot>
					<SplitLayout>
						<SplitCol>
							<Navigation>
								<View id="main" activePanel="main">
									<MainPanel id="main" />
								</View>
								<View id="news" activePanel="news">
									<NewsPanel id='news' />
								</View>
								<View id="calendar" activePanel="calendar">
									<CalendarPanel id='calendar' />
								</View>
								<View nav='profile' id="profile" activePanel="profile">
									<ProfilePanel id='profile'/>
								</View>
							</Navigation>
						</SplitCol>
					</SplitLayout>
				</AppRoot>
			</AdaptivityProvider>
		</ConfigProvider>
	);
}

export default App;
