const CalendarIcon = (props: any) => {
  return (
    <svg {...props} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.72222 2.33301V5.83301" stroke="#818C99" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.2778 2.33301V5.83301" stroke="#818C99" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.90971 10.6045H23.0903" stroke="#818C99" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M23.625 9.91634V19.833C23.625 23.333 22.0208 25.6663 18.2778 25.6663H9.72222C5.97917 25.6663 4.375 23.333 4.375 19.833V9.91634C4.375 6.41634 5.97917 4.08301 9.72222 4.08301H18.2778C22.0208 4.08301 23.625 6.41634 23.625 9.91634Z" stroke="#818C99" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <rect x="7" y="13" width="14" height="10" rx="3" fill="#818C99"/>
    </svg>
  );
}

export default CalendarIcon
