import { Avatar, Banner, Button, CardGrid, ContentCard, Div, FormItem, Gallery, Group, Header, HorizontalCell, HorizontalScroll, IconButton, Link, MiniInfoCell, ModalCard, ModalRoot, Panel, PanelHeader, RichCell, SimpleCell, Spinner, Title } from "@vkontakte/vkui"
import { additionalSections } from "../../data/additionalSections"
import { useEvents } from "../../hooks/useEvents"
import { useNews } from "../../hooks/useNews"
import { TMainPanel } from "./MainPanel"
import Block from "../../components/Block"
import { useEffect, useState } from "react"
import Onboarding from "../../components/Onboardings"
import { useDispatch, useSelector } from "react-redux"
import { setOnboarding, setPanel } from "../../store/slices/panelsSlice"
import { RootState } from "../../store/store"
import bridge from "@vkontakte/vk-bridge"
// import notImage from '/img/not-image.png'

interface IMainViewProps {
  id: TMainPanel
  goToPanel: (value: TMainPanel, id?: string) => void
}

type TTheme = 'dark' | 'light'

const MainView = ({ id, goToPanel }:IMainViewProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const activeModal = useSelector((state: RootState) => state.panels.onboardingState)
  const [importandNews, setImportandNews] = useState<any>()
  const [importandEvents, setImportandEvents] = useState<any>()
  const [theme, setTheme] = useState<TTheme>('light')
  const isNew = useSelector((state: RootState) => state.user.isNew)

  useEffect(() => {
    bridge.send('VKWebAppGetConfig')
    .then((data) => {
      setTheme(data.appearance)
    })
  }, [])

  const news = useNews()
  const events = useEvents()

  useEffect(() => {
    if (news && events) {
      setImportandNews(news.find(item => item.is_important === true))
      setImportandEvents(events.find(item => item.is_important === true))
      if (news.length > 0 || events.length > 0) {
        setIsLoading(false)
        setTimeout(() => {
          if (isNew) {
            dispatch(setOnboarding('onboarding'))
          }
        }, 1000);
      }
    }
  }, [news, events])
  const dispatch = useDispatch()

  return (
    <Panel id={id} className="mainPanel">
      {isLoading ? (
        <Spinner size="large" style={{ height: '90vh' }} />
      ) : (
        <>
          <PanelHeader>Молодёжь 39</PanelHeader>
          <Group>
            {importandNews && (
              <Banner
                mode="image"
                size="m"
                header={importandNews.title}
                subheader={
                  <span
                    style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: '1', overflow: 'hidden' }}
                    dangerouslySetInnerHTML={{__html: importandNews.description}}
                  />
                }
                background={
                  <div
                    style={{
                      backgroundImage: `url(${importandNews.image_url_list[0].url_path})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                    }}
                  />
                }
                actions={
                  <Button appearance="overlay" size="m" onClick={() => goToPanel('newsDetails', importandNews.id )}>
                    Подробнее
                  </Button>
                }
              />
            )}

            {importandEvents && (
              <Banner
                mode="image"
                size="m"
                header={importandEvents.title}
                subheader={
                  <span
                    style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: '1', overflow: 'hidden' }}
                    dangerouslySetInnerHTML={{__html: importandEvents.description}}
                  />
                }
                background={
                  <div
                    style={{
                      backgroundImage: `url(${importandEvents.image_url_list[0] && importandEvents.image_url_list[0].url_path})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                    }}
                  />
                }
                actions={
                  <Button appearance="overlay" size="m" onClick={() => goToPanel('eventDetails', importandEvents.id )}>
                    Подробнее
                  </Button>
                }
              />
            )}
          </Group>


          <Div style={{ paddingTop: '0' }}>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', rowGap: '8px', padding: '8px', backgroundColor: theme === 'dark' ? '#232324' : '#F5F5F5', borderRadius: '16px'}}>
              {additionalSections.map(section => (
                <HorizontalCell
                  key={section.name}
                  size="s"
                  header={section.name}
                  disabled
                  style={{maxWidth: '140px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}
                >
                  {section.link ? (
                    <a href={section.link} target="_blank">
                      <IconButton
                        style={theme === 'light' ? buttonStyles : buttonDarkStyles}
                      >
                        <section.icon fill="#2688EB" />
                      </IconButton>
                    </a>
                  ) : (
                    <IconButton
                      style={theme === 'light' ? buttonStyles : buttonDarkStyles}
                      onClick={section.isModal ? () => dispatch(setOnboarding('onboarding')) : () => goToPanel(section.viewValue)}>
                      <section.icon fill="#2688EB" />
                    </IconButton>
                  )}
                </HorizontalCell>
              ))}
            </div>
          </Div>
          <Onboarding
            activeModal={activeModal}
            onClose={() => dispatch(setOnboarding(null))}
          />
        </>
      )}
    </Panel>
  )
}

const buttonStyles = {
  width: '56px',
  height: '56px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '16px',
  backgroundColor: '#F5F5F5',
}

const buttonDarkStyles = {
  width: '56px',
  height: '56px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '16px',
  backgroundColor: '#2c2c2d',
}

const additionalStyles = {
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)'
}

export default MainView
