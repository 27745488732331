import { CardGrid, ContentCard, Group, Panel, PanelHeader, PanelHeaderBack } from "@vkontakte/vkui"
import { TMainPanel } from "../MainPanel"
import { useWeRussians } from "../../../hooks/useWeRussians"
import axios from "axios"
import { useState, useEffect } from "react"
import { useToken } from "../../../hooks/useToken"
import { INews } from "../../../store/slices/newsSlice"
import { IWeRussian } from "../../../store/slices/weRussiansSlice"
import {formatDatetime} from "../../../utils/fomatDateTime";
import {getUrl} from "../../../api/api";

interface IWeRussiansProps {
  id: TMainPanel
  goToPanel: (value: TMainPanel, id: string) => void
  goBack: (value: TMainPanel) => void
}

const WeRussians = ({ id, goToPanel, goBack }:IWeRussiansProps) => {
  const [weRussians, setWeRussians] = useState<IWeRussian[]>([])
  const [page, setPage] = useState(1)
  const [fetching, setFetching] = useState(true)
  const token = useToken()

  useEffect(() => {
    if (fetching) {
      console.log('fetching')
      axios.get(getUrl(`/api/v1/content/we-russians/index?page=${page}`), {
        headers: { Authorization: `Bearer ${token}` }
      }).then(res => {
        if (page > res.data._meta.pageCount) return
        setWeRussians([...weRussians, ...res.data.data])
        setPage(prev => prev + 1)
      }).finally(() => {
        setFetching(false)
      })
    }
  }, [fetching])

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler)
    return () => {
      document.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  const scrollHandler = (e: any) => {
    if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100) {
      setFetching(true)
    }
  }


  return (
    <Panel id={id}>
      <PanelHeader before={<PanelHeaderBack onClick={() => goBack('main')} />}>Мы Россияне </PanelHeader>
      <Group>
        <CardGrid size="l">
          {weRussians && (
            <>
            {weRussians.length > 0 && weRussians.map(item => (
              <ContentCard
                key={item.id}
                src={item.image_url_list[0] ? item.image_url_list[0]?.url_path : '/img/not-image.png'}
                header={item.title}
                caption={formatDatetime(item.date * 1000, 'details')}
                data-id={item.id}
                onClick={() => goToPanel('weRussiansDetails', item.id)}
                height={112}
              />
            ))}
            </>
          )}
        </CardGrid>
      </Group>
    </Panel>
  )
}

export default WeRussians
