const HomeIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_655_4370)">
            <path d="M11.6667 22.1665V16.3332H16.3333V22.1665C16.3333 22.8082 16.8583 23.3332 17.5 23.3332H21C21.6417 23.3332 22.1667 22.8082 22.1667 22.1665V13.9998H24.15C24.6867 13.9998 24.9433 13.3348 24.535 12.9848L14.7817 4.19984C14.3383 3.80318 13.6617 3.80318 13.2183 4.19984L3.46499 12.9848C3.06833 13.3348 3.31333 13.9998 3.84999 13.9998H5.83333V22.1665C5.83333 22.8082 6.35833 23.3332 6.99999 23.3332H10.5C11.1417 23.3332 11.6667 22.8082 11.6667 22.1665Z" fill="#818C99"/>
        </g>
        <defs>
            <clipPath id="clip0_655_4370">
                <rect width="28" height="28" fill="white"/>
            </clipPath>
        </defs>
    </svg>
  );
}

export default HomeIcon
