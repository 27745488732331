import { Button, Card, CardGrid, Div, FixedLayout, Gallery, Group, Panel, PanelHeader, PanelHeaderBack, Paragraph, SimpleCell, Switch, Title } from "@vkontakte/vkui"
import { TNewsPanel } from "./NewsPanel"
import { Key, useEffect, useState } from "react"
import { getNewsDetails } from "../../api/requests"
import { TMainPanel } from "../Main/MainPanel"
import { useToken } from "../../hooks/useToken"
import axios from "axios"
import {getUrl} from "../../api/api";

interface INewsDetailsProps {
  id: TNewsPanel | TMainPanel
  newsId: string
  goBack: () => void
}

const NewsDetails = ({ id, newsId, goBack }:INewsDetailsProps) => {
  const [newsDetails, setNewsDetails] = useState<any>()
  const [token] = useToken()
  const [isSelected, setIsSelected] = useState(false)

  useEffect(() => {
    getNewsDetails(newsId, token).then(res => {
      const data = res.data.data
      setNewsDetails(data)
      if (data.event) {
        if (new Date(data.event.date_start * 1000) < new Date()) {
          setIsSelected(data.is_was_present)
        } else {
          setIsSelected(data.is_will_go)
        }
      }
    })
  }, [])

  const handleWillGo = () => {
    if (newsDetails) {
      if (newsDetails.event) {
        if (newsDetails.event.is_will_go) {
          axios.post(getUrl('/api/v1/events/event/will-go-delete'), {event_id: newsDetails.event.id}, {
            headers: { Authorization: `Bearer ${token}` },
          })
          newsDetails.event.is_will_go = false
          setIsSelected(false)
        } else {
          axios.post(getUrl('/api/v1/events/event/will-go'), {event_id: newsDetails.event.id}, {
            headers: { Authorization: `Bearer ${token}` },
          })
          newsDetails.event.is_will_go = true
          setIsSelected(true)
        }
      }
    }
  }

  const handleWasPresent = () => {
    if (newsDetails) {
      if (newsDetails.event) {
        if (newsDetails.event.is_was_present) {
          axios.post(getUrl('/api/v1/events/event/was-present-delete'), {event_id: newsDetails.event.id}, {
            headers: { Authorization: `Bearer ${token}` },
          })
          newsDetails.event.is_was_present = false
          setIsSelected(false)
        } else {
          axios.post(getUrl('/api/v1/events/event/was-present'), {event_id: newsDetails.event.id}, {
            headers: { Authorization: `Bearer ${token}` },
          })
          newsDetails.event.is_was_present = true
          setIsSelected(true)
        }
      }
    }
  }

  return (
    <Panel id={id}>
      <PanelHeader before={<PanelHeaderBack onClick={goBack} />}>Молодёжь 39 </PanelHeader>
      <Group>
        {newsDetails && (
          <Div>
            <Gallery slideWidth="100%%" bullets="light" style={{borderRadius: '16px', marginBottom: '16px'}}>
              {newsDetails.image_url_list.length > 0 && newsDetails.image_url_list.map((url: { id: Key | null | undefined; url_path: any; file_original_name: string | undefined }) => (
                <img
                  key={url.id}
                  src={url.url_path}
                  style={{ display: 'block', objectFit: 'cover' }}
                  alt={url.file_original_name}
                  height={200}
                />
              ))}
            </Gallery>
            <Title level="2" style={{ marginBottom: '8px' }}>
              {newsDetails.title}
            </Title>
            <Paragraph>
              <p dangerouslySetInnerHTML={{__html: newsDetails.description}} />
            </Paragraph>
          </Div>
        )}
        {(newsDetails !== undefined && newsDetails.event) && (
          <div>
            {newsDetails.vk_video && (
              <Div>
                <iframe src={ newsDetails.vk_video } width="100%" height="320"
                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameBorder="0"
                allowFullScreen></iframe>
              </Div>
            )}
            <Div>
              <Card>
                {new Date(newsDetails.event.date_start * 1000) < new Date() ? (
                    <SimpleCell Component="label" after={<Switch checked={isSelected} onChange={() => handleWasPresent()} />} style={{borderRadius: '8px'}}>
                      Я посетил
                    </SimpleCell>
                ) : (
                    <SimpleCell Component="label" after={<Switch checked={isSelected} onChange={() => handleWillGo()} />} style={{borderRadius: '8px'}}>
                      Я пойду
                    </SimpleCell>
                )}
              </Card>
            </Div>
            <Div>
              <a style={{ textDecoration: 'none' }} href={ 'https://vk.com/share.php?url=https://vk.com/app51632365_38068314&title=' + newsDetails.title } target="_blank">
                <Button size="l" stretched>
                  Рассказать
                </Button>
              </a>
              {/*<a style={{ textDecoration: 'none' }} target="_blank" href="https://vkontakte.ru/share.php?url=Ссылка">

              </a>*/}
            </Div>
          </div>
        )}
      </Group>
    </Panel>
  )
}

export default NewsDetails
