import { Icon20ChevronRight, Icon24Filter } from "@vkontakte/icons";
import { Search, Spacing, Separator, RichCell } from "@vkontakte/vkui";
import { useState, useEffect } from "react";
import { TCalendarPanel } from "../CalendarPanel";
import Block from "../../../components/Block";
import { IEvent } from "../../../store/slices/eventsSlice";
import { formatDatetime } from "../../../utils/fomatDateTime";

interface IEventsListProps {
  eventsData: any
  goToDetails: (id: string) => void
}

const EventsList = ({ eventsData, goToDetails }:IEventsListProps) => {
  const [filteredEvents, setFilteredEvents] = useState<IEvent[]>(eventsData)
  
  useEffect(() => {
    setFilteredEvents(eventsData)
  }, [eventsData])


  return (
    <>
      {filteredEvents.length > 0 && filteredEvents.map((item) => (
        <Block>
          <RichCell
            key={item.id}
            style={{alignItems: 'center'}} 
            subhead={item.type_title}
            caption={formatDatetime(item.date_start, 'list')}
            onClick={() => goToDetails(item.id)}
          >
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              {item.title} <Icon20ChevronRight fill="#B8C1CC" />
            </div>
          </RichCell>
        </Block>
      ))}
    </>
  )
}

export default EventsList