import { Panel, File, PanelHeader, PanelHeaderBack, Group, Avatar, FormLayout, FormItem, Input, Button, Div } from "@vkontakte/vkui"
import { useState } from "react"
import { IUser } from "../../store/slices/userSlice"
import { TProfilePanel } from "./ProfilePanel"
import axios from "axios"
import { useToken } from "../../hooks/useToken"

interface IProfileSettingsProps {
  id: TProfilePanel
  goBack: (value: TProfilePanel) => void
  user: IUser | null
}

const ProfileSettings = ({ id, goBack, user }: IProfileSettingsProps) => {
  const [name, setName] = useState<string>(user ? user.full_name : '')
  const [age, setAge] = useState<string | undefined>(user ? user.age : '')
  const [city, setCity] = useState<string>(user ? user.city : '')
  const [phone, setPhone] = useState<string | undefined>(user ? user.phone : '')
  const token = useToken()

  const changeAvatar = (e: any) => {
    console.log(e.target.files)
  }

  const saveInfo = () => {
    if (!user) return
    const userInfo = {
      full_name: name,
      city: city,
      age: age,
      phone: phone,
      vk_user_id: user.vk_user_id,
      login: user.login
    }

    axios.post(`api/v1/user/user/update?id=${user.id}`, userInfo, {
      headers: { Authorization: `Bearer ${token}` },
    })
  }

  /*
    <File style={{ backgroundColor: 'transparent', color: '#2688EB' }} onChange={changeAvatar}>
      Сменить фото
    </File>
  */

  return (
    <Panel id={id}>
      <PanelHeader before={<PanelHeaderBack onClick={() => goBack('info')} />}>Молодёжь 39</PanelHeader>
      {user && (
        <Group>
          <Div style={{ display: 'flex', flexDirection: 'column' ,alignItems: "center" }}>
            <Avatar size={88} src={user.avatar} />
          </Div>
          <FormLayout>
            <FormItem
              htmlFor="name"
              top="ФИО"
              status={name ? 'default' : 'error'}
              bottom={ !name && 'Введите ФИО' }
            >
              <Input id="name" value={name} onChange={(e) => setName(e.target.value)} />
            </FormItem>
            <FormItem
              htmlFor="age"
              top="Возраст"
            >
              <Input type="number" id="age" value={age} onChange={(e) => setAge(String(e.target.value))} />
            </FormItem>
            <FormItem
              htmlFor="city"
              top="Город"
            >
              <Input id="city" value={city} onChange={(e) => setCity(e.target.value)} />
            </FormItem>
            <FormItem
              htmlFor="phone"
              top="Номер телефона"
            >
              <Input id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} type="tel" />
            </FormItem>
            <FormItem>
              <Button size="l" stretched onClick={saveInfo}>
                Сохранить
              </Button>
            </FormItem>
          </FormLayout>
        </Group>
      )}
    </Panel>
  )
}

export default ProfileSettings
