import { Panel, PanelHeader, PanelHeaderBack, Group, Div, Gallery, Title, Paragraph, FixedLayout, Button } from "@vkontakte/vkui"
import { useState, useEffect, Key } from "react"
import { getWeRussiansDetails } from "../../../api/requests"
import { useToken } from "../../../hooks/useToken"
import { TNewsPanel } from "../../News/NewsPanel"
import { TMainPanel } from "../MainPanel"

interface IWeRussiansProps {
  id: TNewsPanel | TMainPanel
  weRussiansId: string
  goBack: () => void
}

const WeRussiansDetails = ({ id, weRussiansId, goBack }:IWeRussiansProps) => {
  const [weRussiansDetails, setWeRussiansDetails] = useState<any>()
  const [token] = useToken()
  useEffect(() => {
    getWeRussiansDetails(weRussiansId, token).then(res => setWeRussiansDetails(res.data.data))
  }, [])

  return (
    <Panel id={id}>
      <PanelHeader before={<PanelHeaderBack onClick={goBack} />}>Молодёжь 39 </PanelHeader>
      <Group>
        {weRussiansDetails && (
          <Div>
            <Gallery slideWidth="100%%" bullets="light" style={{borderRadius: '16px', marginBottom: '16px'}}>
              {weRussiansDetails.image_url_list.length > 0 && weRussiansDetails.image_url_list.map((url: { id: Key | null | undefined; url_path: any; file_original_name: string | undefined }) => (
                <img
                  key={url.id}
                  src={url.url_path}
                  style={{ display: 'block', objectFit: 'cover' }}
                  alt={url.file_original_name}
                  height={200}
                />
              ))}
            </Gallery>
            <Title level="2" style={{ marginBottom: '8px' }}>
              {weRussiansDetails.title}
            </Title>
            <Paragraph>
              <p dangerouslySetInnerHTML={{__html: weRussiansDetails.description}} />
            </Paragraph>
          </Div>
        )}
        <FixedLayout vertical="bottom" filled>
          <Div>
            <Button size="l" stretched>
              Рассказать
            </Button>
          </Div>
        </FixedLayout>
      </Group>
    </Panel>
  )
}

export default WeRussiansDetails
