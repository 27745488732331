import { SplitLayout, SplitCol, Epic, Tabbar, TabbarItem } from "@vkontakte/vkui";
import React from "react";
import { TViewValue, navData } from "../data/navData";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { setPanel } from "../store/slices/panelsSlice";

interface INavigationProps {
  children: React.ReactNode
}

const Navigation = ({ children }: INavigationProps) => {
  const activeStory = useSelector((state: RootState) => state.panels.panelState)
  const dispatch = useDispatch()
  const onStoryChange = (e: any) => {
      console.log(e)
      dispatch(setPanel(e.currentTarget.dataset.story))
  };

  return (
    <SplitLayout>
      <SplitCol width="100%" maxWidth="560px" stretchedOnMobile autoSpaced>
        <Epic
          activeStory={activeStory}
          tabbar={(
            <Tabbar>
              {navData.map(item => (
                <TabbarItem
                  key={item.viewValue}
                  onClick={onStoryChange}
                  selected={activeStory === item.viewValue}
                  data-story={item.viewValue}
                  text={item.name}
                >
                  {<item.icon />}
                </TabbarItem>
              ))}
            </Tabbar>
          )}
        >
          {children}
        </Epic>
      </SplitCol>
    </SplitLayout>
  );
};

export default Navigation
